import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "D:/a/1/s/kope-docs-user/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`Prepare all the offsite products you need for your project using Supply Chains.`}</p>
    </PageDescription>
    <p>{`With so many organizations and products on the KOPE platform, we use Supply Chains to help manage them to your specific needs. Be that grouping by capabilities, locations, or past collaboration, you can use this functionality to prepare all the offsite products you need for a project.`}</p>
    <p>{`Click on `}<inlineCode parentName="p">{`New supply chain`}</inlineCode>{` to add registered organizations.`}</p>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/getting-started/11_supply-chains-new-supply-chain.png",
      "alt": "11_supply-chains-new-supply-chain.png"
    }}></img>
    <p>{`You can always adjust your groupings by adding or removing relevant organizations as your use of KOPE evolves.`}</p>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/getting-started/12_supply-chains-example-supply-chain.png",
      "alt": "12_supply-chains-example-supply-chain.png"
    }}></img>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      